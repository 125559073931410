import { useEffect, useState, useRef } from 'react';
import { Button,  Container, Modal, Image } from 'react-bootstrap';
import portrait from './assets/portrait.jpg'
import { FaAngleDown, FaAngleUp, FaEnvelope, FaReact, FaBootstrap, FaLinkedin } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { MdSchool, MdWork } from "react-icons/md";
import './App.css';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import "animate.css/animate.compat.css"
import { Switch, Case, Default, If, Then, Else } from 'react-if';
import ScrollAnimation from 'react-animate-on-scroll';
import { DeviceFrameset } from 'react-device-frameset'
import 'react-device-frameset/styles/marvel-devices.min.css'

function App() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const divFirstRef = useRef();
  const scrollToFirstDiv = () => {
    if (divFirstRef.current) {
      smoothScrollTo(document.documentElement, divFirstRef.current.offsetTop, 300);
    }
  };

  const divMeRef = useRef();
  const scrollToMeDiv = () => {
    if (divMeRef.current) {
      smoothScrollTo(document.documentElement, divMeRef.current.offsetTop, 900);
    }
  };

  const divAwardRef = useRef();
  const scrollToAwardDiv = () => {
    if (divAwardRef.current) {
      smoothScrollTo(document.documentElement, divAwardRef.current.offsetTop, 900);
    }
  };

  const divProjectRef = useRef();
  const scrollToProjectDiv = () => {
    if (divProjectRef.current) {
      smoothScrollTo(document.documentElement, divProjectRef.current.offsetTop, 900);
    }
  };

  const divEduRef = useRef();
  const scrollToEduDiv = () => {
    if (divEduRef.current) {
      smoothScrollTo(document.documentElement, divEduRef.current.offsetTop, 900);
    }
  };

  const divLastRef = useRef();
  const scrollToLastDiv = () => {
    if (divLastRef.current) {
      smoothScrollTo(document.documentElement, divLastRef.current.offsetTop, 900);
    }
  };


  function smoothScrollTo(element, to, duration) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;
  
    function animateScroll() {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      }
    }
    animateScroll();
  }
  
  
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
   
      setHeight(window.innerHeight);
      setWidth(window.innerWidth)
      
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions) 

  }, []);


  return (
    <>

<Modal show={show} onHide={handleClose} fullscreen>
      
        <Modal.Body style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div onClick={handleClose} className={"opacity-hover"} style={{display:"flex",position: "absolute",justifyContent: "center", alignItems: "center", top: 10, right: 10, padding: 0, fontSize: "2rem", color: "whitesmoke", cursor: "pointer"}}><IoCloseCircle/></div>
          <div style={{display: "flex", justifyContent: "center"}}>
            <iframe src='https://mydashboard.at' width={width-100} height={height-100} style={{padding: 0, margin: 0,borderRadius: 25, border: "solid 15px black", outline: "solid 2px silver", boxShadow: "0 0 1px 7px whitesmoke"}}/>
            </div>
     
          
        
        </Modal.Body>
        
      </Modal>
    <div ref={divFirstRef} className='banner'  style={{height: height, width: width, backgroundImage: `url(${require("./assets/banner.jpg")})`, backgroundSize: "cover", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>
   
       
      <div>
   
   
      
      
  
     <center><Image src={portrait} roundedCircle width={150}  />
     <br/><FaLinkedin className='mail' style={{marginTop: 20,  fontSize: "2rem"}}  onClick={()=> {window.open("https://www.linkedin.com/in/marco-schöll-ba38b729b/", "_blank", "noreferrer");}}/>
      
   <h1 style={{marginBottom: 50 ,marginTop: 30}}>Ich bin Marco Schöll!</h1>
   <FaAngleDown className='arrow' onClick={scrollToMeDiv}/>
   </center>
   </div>
  
    </div>
    <div ref={divMeRef} style={{minHeight: height, width: width, backgroundColor: "#161A1F", backgroundImage: `url(${require("./assets/me.jpg")})`, backgroundSize: "cover",  display: "flex", justifyContent: "center", alignItems: "center", color: "#fff"}}>
    <div style={{textAlign: "center"}}>
    <ScrollAnimation animateIn='fadeIn'>


      <div style={{maxWidth: 500, padding: 30, borderRadius: 5}}>
      <h3>Als Führungskraft ist es mein Ziel, nicht nur erfolgreich zu sein, sondern auch meine Mitarbeiter zu motivieren und ihr volles Potenzial zu entfalten. <div className='hr'/><h4>Dadurch können wir große Ziele erreichen und eine positive Wirkung hinterlassen.</h4></h3>
    
      </div>
      </ScrollAnimation>
      <FaAngleDown className='arrow' onClick={scrollToAwardDiv}/>
    </div>
    </div>

    <div ref={divAwardRef} style={{minHeight: height, width: width, backgroundColor: "#161A1F", backgroundImage: `url(${require("./assets/award.jpg")})`, backgroundSize: "cover",  display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", paddingTop: 100, paddingBottom: 100}}>
   <div><Container fluid>
   <center><h1>Meine Auszeichnungen</h1></center>
    <VerticalTimeline>
  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
  >
    <h3 className="vertical-timeline-element-title" style={{minWidth: width/2}}>Goldene Tanne</h3>
  
    <p>
   2023
    </p>
  </VerticalTimelineElement>
  



  
</VerticalTimeline>
</Container>
<center>
    <FaAngleDown className='arrow' onClick={scrollToProjectDiv}/></center>
    </div>
    </div>

    <div ref={divProjectRef} style={{minHeight: height, width: width, backgroundColor: "#161A1F", backgroundImage: `url(${require("./assets/project.jpg")})`, backgroundSize: "cover",  display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", paddingTop: 100, paddingBottom: 100}}>
   <div><Container fluid>
   <center><h1>Meine Projekte</h1></center>
    <VerticalTimeline>
  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
   
  >
    <h3 className="vertical-timeline-element-title" style={{minWidth: width/2}}>myDashboard</h3>
  
    <p>
    Effiziente ToDo-List-App für Mitarbeiter: Fördert Talententwicklung durch präzises Aufgabenmanagement und strukturierte Feedbackgespräche.</p>
    {/*<Button style={{width: "100%"}} onClick={handleShow} size="sm" variant="light" disabled>Anzeigen</Button>*/}
  </VerticalTimelineElement>
  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
   
  >
    <h3 className="vertical-timeline-element-title" style={{minWidth: width/2}}>SPAR Artikelsuche</h3>
  
    <p>
    Eine intuitive App zur Artikelsuche, angelehnt an den Interspar Onlineshop, die den Bestellvorgang, die Bestandskontrolle und die Kassatätigkeit optimiert und Arbeitsprozesse effizienter gestaltet.
    </p>
    {/*<Button style={{width: "100%"}} onClick={handleShow} size="sm" variant="light" disabled>Anzeigen</Button>*/}
  </VerticalTimelineElement>
  



  
</VerticalTimeline>
</Container>
<center>
    <FaAngleDown className='arrow' onClick={scrollToEduDiv} /></center>
    </div>
    </div>

    <div ref={divEduRef} style={{minHeight: height, width: width, backgroundColor: "#161A1F", backgroundImage: `url(${require("./assets/content.jpg")})`, backgroundSize: "cover",  display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", paddingTop: 100, paddingBottom: 100}}>
   <div><Container fluid>
   <center><h1>Mein Weg</h1></center>
    <VerticalTimeline>
  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
  >
    <h3 className="vertical-timeline-element-title" style={{minWidth: width/2}}>Spar AG</h3>
    <h4 className="vertical-timeline-element-subtitle">Marktleiter</h4>
    <p>
   2019 - Heute
    </p>
  </VerticalTimelineElement>
  
  <VerticalTimelineElement

    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
  >
     <h3 className="vertical-timeline-element-subtitle" style={{minWidth: width/2}}>Zsabetich GmbH</h3>
    <h4 className="vertical-timeline-element-title">Marktleiter</h4>
   
    <p>
   2014 - 2019
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
 
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
  >
     <h3 className="vertical-timeline-element-subtitle" style={{minWidth: width/2}}>Zsabetich GmbH</h3>
    <h4 className="vertical-timeline-element-title">Filialmitarbeiter</h4>
   
    <p>
   2012 - 2014
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdWork/>}
  >
       <h3 className="vertical-timeline-element-subtitle" style={{minWidth: width/2}}>Billa AG</h3>
    <h4 className="vertical-timeline-element-title">Filialmitarbeiter</h4>
 
    <p>
   2010 - 2012
    </p>
  </VerticalTimelineElement>



  
</VerticalTimeline>
</Container>
<center>
    <FaAngleDown className='arrow' onClick={scrollToLastDiv}/></center>
    </div>
    </div>


    <div ref={divLastRef} style={{minHeight: height, width: width, backgroundColor: "#161A1F", backgroundImage: `url(${require("./assets/education.jpg")})`, backgroundSize: "cover", display: "flex", justifyContent: "center", alignItems: "center", color: "#fff", paddingTop: 100, paddingBottom: 100}}>
    <div><Container fluid>
      <center><h1>Meine Erfahrung</h1></center>
    <VerticalTimeline>
  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdSchool/>}
  >
    <h3 className="vertical-timeline-element-title" style={{minWidth: width/2}}>Meisterausbildung</h3>
    <h4 className="vertical-timeline-element-subtitle">Spar AG</h4>
    <small>Talententwicklung durch präzises Aufgabenmanagement und strukturierte Feedbackgespräche</small>
    <p>
   2023
    </p>
  </VerticalTimelineElement>
  
  

  <VerticalTimelineElement
 
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdSchool/>}
  >
     <h3 className="vertical-timeline-element-subtitle" style={{minWidth: width/2}}>Lehrlingsausbilder</h3>
    <h4 className="vertical-timeline-element-title">Integral Humans</h4>
   
    <p>
   2020
    </p>
  </VerticalTimelineElement>



  <VerticalTimelineElement
   
    contentStyle={{ background: 'none', color: '#fff' }}
    iconStyle={{ background: 'none', color: '#fff' }}
    icon={<MdSchool style={{fontSize: "5rem"}}/>}
  >
       <h3 className="vertical-timeline-element-subtitle" style={{minWidth: width/2}}>Handelsschule</h3>
    <h4 className="vertical-timeline-element-title">Frauenkirchen</h4>
 
    <p>
   2007 - 2010
    </p>
  </VerticalTimelineElement>



  
</VerticalTimeline>

</Container>
<center>
<FaAngleUp className='arrow' onClick={scrollToFirstDiv}/>


    <p style={{marginTop: 20}}>
           
          Quergasse 40/2 - 7123 Mönchhof, Österreich<br/><br/> <a href="mailto:marco.schoell@proton.me" style={{textDecoration: "none", color: "#3d9df2"}}>marco.schoell@proton.me</a>
          </p>  
          <div style={{opacity: 0.4, marginTop: 20}}><h3><FaReact/> + <FaBootstrap/></h3>
<small>© 2024 Marco Schöll

           
    </small>   </div>
</center>

    </div>
    
    </div>

   

    </>
  );
}

export default App;
